import PropTypes from 'prop-types'

import { gql, useMutation } from '@apollo/client'

import withModal from '../../hoc/withModal/withModal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import Spinner from '../spinner/Spinner'
import Button from '../button/Button'

const DELETE_KEYWORD_MUTATION = gql`
  mutation DeleteKeyword($id: ID!) {
    deleteKeyword(id: $id) {
      id
    }
  }
`

const ME_QUERY = gql`
  query MeQuery {
    me {
      id
    }
  }
`

function DeleteKeyword(props) {
  const keyword = props.modalOptions?.keyword

  const [deleteKeyword, { loading, error }] = useMutation(DELETE_KEYWORD_MUTATION, {
    variables: { id: keyword.id },
    onCompleted: () => {
      props.closeModal()
    },
    update: (cache) => {
      function removeKeywordFromCacheList(existingKeywordRefs, { readField }) {
        return existingKeywordRefs.filter(keywordRef => keyword.id !== readField('id', keywordRef))
      }

      const cachedId = cache.identify(keyword)

      const { me } = cache.readQuery({ query: ME_QUERY })
      // Remove keyword from list
      cache.modify({
        id: cache.identify(me),
        fields: {
          keywords: removeKeywordFromCacheList,
        }
      })
      // Remove unused keyword
      cache.evict(cachedId)
    },
    onError: (e) => console.error(e),
  })

  if (error) {
    return (
      <ModalSection>
        <ModalHeader
          title="Delete a keyword"
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured deleting...</p>
        </ModalContent>
      </ModalSection>
    )
  }

  return (
    <ModalSection>
      <ModalHeader
        title="Delete a keyword"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <h6>Are you sure you want to delete keyword <em>"{keyword.name}"</em>?</h6>
        <p>It's attached to {keyword.count} photo{keyword.count === 1 ? "" : "s"}.</p>
        <p><em>(this operation is irreversible)</em></p>
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Deleting keyword..." />}
        <Button
          onClick={deleteKeyword}
          primary={true}
        >Delete keyword</Button>
      </ModalFooter>
    </ModalSection>
  )
}

DeleteKeyword.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    keyword: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      count: PropTypes.number,
    })
  }).isRequired,
}

export default withModal(DeleteKeyword)