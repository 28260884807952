
import { modalTypes } from '../../modal'


// Modal components
import Logout from '../logout/Logout'
import UploadAvatar from '../uploadAvatar/UploadAvatar'
import UploadPhotos from '../uploadPhotos/UploadPhotos'
import DeletePhotos from '../deletePhotos/DeletePhotos'
import UpdatePassword from '../updatePassword/UpdatePassword'
import UpdateEmail from '../updateEmail/UpdateEmail'

import CreateAlbum from '../createAlbum/CreateAlbum'
import RenameAlbum from '../renameAlbum/RenameAlbum'
import MoveAlbum from '../moveAlbum/MoveAlbum'
import DeleteAlbum from '../deleteAlbum/DeleteAlbum'

import CreateFolder from '../createFolder/CreateFolder'
import RenameFolder from '../renameFolder/RenameFolder'
import MoveFolder from '../moveFolder/MoveFolder'
import DeleteFolder from '../deleteFolder/DeleteFolder'

import AddPhotosToAlbum from '../addPhotosToAlbum/AddPhotosToAlbum'
import RemovePhotosFromAlbum from '../removePhotosFromAlbum/RemovePhotosFromAlbum'

import RenameKeyword from '../renameKeyword/RenameKeyword'
import DeleteKeyword from '../deleteKeyword/DeleteKeyword'


function Modal() {
  return (
    <div>
      <Logout
        id={modalTypes.LOGOUT}
        closable={false}
      />
      <UploadAvatar
        id={modalTypes.UPLOAD_AVATAR}
        closable={false}
      />
      <UploadPhotos
        id={modalTypes.UPLOAD_PHOTOS}
        closable={false}
      />
      <DeletePhotos
        id={modalTypes.DELETE_PHOTOS}
        closable={false}
      />
      <UpdatePassword
        id={modalTypes.UPDATE_PASSWORD}
        closable={false}
      />
      <UpdateEmail
        id={modalTypes.UPDATE_EMAIL}
        closable={false}
      />
      <CreateAlbum
        id={modalTypes.CREATE_ALBUM}
        closable={false}
      />
      <RenameAlbum
        id={modalTypes.RENAME_ALBUM}
        closable={false}
      />
      <MoveAlbum
        id={modalTypes.MOVE_ALBUM}
      />
      <DeleteAlbum
        id={modalTypes.DELETE_ALBUM}
      />
      <CreateFolder
        id={modalTypes.CREATE_FOLDER}
        closable={false}
      />
      <RenameFolder
        id={modalTypes.RENAME_FOLDER}
        closable={false}
      />
      <MoveFolder
        id={modalTypes.MOVE_FOLDER}
      />
      <DeleteFolder
        id={modalTypes.DELETE_FOLDER}
      />
      <AddPhotosToAlbum
        id={modalTypes.ADD_PHOTOS_TO_ALBUM}
      />
      <RemovePhotosFromAlbum
        id={modalTypes.REMOVE_PHOTOS_FROM_ALBUM}
      />
      <RenameKeyword
        id={modalTypes.RENAME_KEYWORD}
      />
      <DeleteKeyword
        id={modalTypes.DELETE_KEYWORD}
      />
    </div>
  )
}


export default Modal
