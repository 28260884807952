
import { gql, useQuery } from '@apollo/client'

import { 
  modalTypes,
  modalStatusVar,
  modalOptionsVar,
} from '../../modal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'

import styles from './userKeywords.module.css'

const MY_KEYWORDS_QUERY = gql`
  query MyKeywordsQuery {
    me {
      id
      keywords {
        id
        name
        count
      }
    }
  }
`
function UserKeywords() {

  const { loading, data, error } = useQuery(MY_KEYWORDS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  if (loading) return <Spinner />
  if (error) {
    return <ErrorMessage error={error} />
  }
  return (
    <ModalSection>
      <ModalHeader
        title="My keywords"
      />
      <ModalContent>
        <ul className={styles.list}>
          {data.me.keywords.map(keyword => 
            <li key={keyword.name}>
              {keyword.name} 
              <div className={styles.buttonsWrapper}>
                  <div className={styles.count}>{keyword.count}</div>
                  <button
                    onClick={() => {
                      modalStatusVar(modalTypes.RENAME_KEYWORD)
                      modalOptionsVar({ keyword })
                    }}
                  >rename</button>
                  <button
                    onClick={() => {
                      modalStatusVar(modalTypes.DELETE_KEYWORD)
                      modalOptionsVar({ keyword })
                    }}
                  >delete</button>
                </div>
            </li>
          )}
        </ul>
      </ModalContent>
      <ModalFooter />
    </ModalSection>
  )
}

export default UserKeywords