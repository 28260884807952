import PropTypes from 'prop-types'
import { useState, useRef } from 'react'
import {
  gql,
  useLazyQuery,
  useMutation,
} from '@apollo/client'

import styles from './photoKeywords.module.css'

const KEYWORD_SUGGESTIONS_QUERY = gql`
  query KeywordSuggestionsQuery($token: String!, $actualKeywords: [String]!, $first: Int) {
    keywordSuggestions(token: $token, actualKeywords: $actualKeywords, first: $first) {
      name
    }
  }
`

const ADD_KEYWORD_MUTATION = gql`
  mutation AddKeywordToPhoto($name: String!, $photoID: ID!) {
    addKeywordToPhoto(name: $name, photoID: $photoID) {
      id
      keywords {
        id
        name
      }
    }
  }
`


const REMOVE_KEYWORD_MUTATION = gql`
  mutation RemoveKeywordFromPhoto($keywordID: ID!, $photoID: ID!) {
    removeKeywordFromPhoto(keywordID: $keywordID, photoID: $photoID) {
      id
      keywords {
        id
        name
      }
    }
  }
`

function parseKeywordsString(keywordsString) {
  let keywords = []
  const segments = keywordsString.slice().split(',')
  for (let segment of segments) {
    const keyword = segment.trim()
    if (keyword) {
      keywords.push(keyword)
    }
  }
  return keywords
}

function getToken(keywordsString, selectionStart) {
  const lastChar = keywordsString.substring(selectionStart - 1, selectionStart)
  if (lastChar === ' ' || lastChar === ',') return ''
  const segments = parseKeywordsString(keywordsString.substring(0, selectionStart))
  return segments[segments.length - 1]
}


function PhotoKeywords({ photoID, keywords }) {
  const [keywordsString, setKeywordString] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [selected, setSelected] = useState(null)
  const inputRef = useRef(null)

  const [getKeywordSuggestions, { error, data }] = useLazyQuery(KEYWORD_SUGGESTIONS_QUERY)
  
  const [addKeyword] = useMutation(ADD_KEYWORD_MUTATION, {
    onError: (e) => { console.error(e) },
  })

  const [removeKeyword] = useMutation(REMOVE_KEYWORD_MUTATION, {
    onError: (e) => { console.error(e) },
  })


  const completeSuggestion = (index) => {
    // complete current input with selected suggestions, without validation
    // or adding trailing space (so user can extend suggestion)
    const keyword = data.keywordSuggestions[index].name
    const current = inputRef.current
    const token = getToken(keywordsString, current.selectionStart)
    let keywordEnd = keyword.slice(token.length)
    /*
      * Document.execCommand, albeit deprecated, allows us to keep browser undo history working
      */
    document.execCommand("insertText", false, keywordEnd)
  }


  const selectSuggestion = (index) => {
    const selectedKeyword = data.keywordSuggestions[index].name
    let selectionStart = inputRef.current.selectionStart
    // We add ',' after cursor to be sure token will be separated from the end of tagString
    const tempString = keywordsString.substring(0, selectionStart) + ',' + keywordsString.substring(selectionStart)
    const newKeywords = parseKeywordsString(tempString)

    if (newKeywords.length === 1) {
      // Only one keyword in input, we just add it to photo
      handleAddKeywords(selectedKeyword)
    } else {
      // We are in a complex keywords string, add new keyword to it
      // and let user validate later
      let selectionEnd = inputRef.current.selectionEnd
      const token = getToken(keywordsString, selectionStart)
      let newKeywordEnd = selectedKeyword.slice(token.length)
      const nextChar = keywordsString.substring(selectionEnd, selectionEnd + 1)
      if (nextChar === ' ') {
        newKeywordEnd += ','
      } else if (nextChar !== ',') {
        newKeywordEnd += ', '
      }

      /*
       * Document.execCommand, albeit deprecated, allows us to keep browser undo history working
       */
      document.execCommand("insertText", false, newKeywordEnd)
      if (nextChar === ' ') {
        /*
         * space was not added by insertText,
         * so we manually move cursor after existing space.
         * if nextChar is newLine we don't change position.
         */
        selectionStart = selectionEnd = selectionEnd + 1
      }
    }
    setShowSuggestions(false)
  }

  const handleKeywordsStringChange = (e) => {
    const newValue = e.target.value
    const token = getToken(newValue, e.target.selectionStart)
    if (token) {
      let newKeywords = parseKeywordsString(newValue)
      const savedKeywords = keywords.map(keyword => keyword.name)
      
      // We don't send token in actual keywords
      const index = newKeywords.indexOf(token)
      if (index !== -1) {
        newKeywords.splice(index, 1)
      }

      const actualKeywords = [...savedKeywords, ...newKeywords]
      // We start suggestion request
      getKeywordSuggestions({
        variables: { token, actualKeywords, first: 5 },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
          setSuggestions(data.keywordSuggestions)
          if (data.keywordSuggestions.length > 0) {
            setShowSuggestions(true)
          } else {
            setShowSuggestions(false)
          }
        }
      })
      setSelected(null)
    } else {
      // We reset suggestions
      setShowSuggestions(false)
    }
    setKeywordString(newValue)
  }

  const handleKeyDown = (e) => {
    /* we don't handle shortcuts if input hasn't focus */
    if (document.activeElement !== inputRef.current) return

    /* 
     * We stop propagation to avoid over components shortcuts handler to trigger
     * (like underlying navigation)
     */
    e.stopPropagation()

    if (e.key === 'Enter' && (!showSuggestions || selected === null)) {
      e.preventDefault()
      // Add current keywordsString to photo's keywords
      handleAddKeywords(keywordsString)
      setShowSuggestions(false)
    }

    /* we don't handle next shortcuts if we have no suggestions */
    if (!showSuggestions) return

    if (e.key === 'ArrowDown') {
      e.preventDefault()
      if (selected !== null && selected < suggestions.length - 1) setSelected(selected + 1)
      else setSelected(0)
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault()
      if (selected !== null && selected > 0) setSelected(selected - 1)
      else setSelected(suggestions.length - 1)
    }
    if (e.key === 'ArrowRight') {
      if (selected !== null) {
        e.preventDefault()
        completeSuggestion(selected)
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault()
      if (selected !== null) selectSuggestion(selected)
    }
    if (e.key === 'Escape') {
      e.preventDefault()
      setSelected(null)
      setShowSuggestions(false)
    }
  }

  const handleBlur = () => {
    setSuggestions([])
    setShowSuggestions(false)
  }

  const handleAddKeywords = (keywordsString) => {
    setKeywordString('') // we reset input
    const actualKeywords = keywords.map(keyword => keyword.name)
    const newKeywords = parseKeywordsString(keywordsString)
    for (let newKeyword of newKeywords) {
      if (!actualKeywords.includes(newKeyword)) {
        addKeyword({variables: { name: newKeyword, photoID }})
      }
    }
  }

  const handleRemoveKeyword = (keywordID) => {
    removeKeyword({variables: { keywordID, photoID }})
  }

  if (error) console.error(error)

  return (
    <div className={styles.wrapper}>
      <label htmlFor={`id-keywords`}>Keywords</label>
      <input
        id="id-keywords"
        name="keywords"
        type="text"
        ref={inputRef}
        value={keywordsString}
        onChange={handleKeywordsStringChange}
        placeholder="Enter keywords (coma separated)"
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
      <div>
      { showSuggestions && (
        <ul className={styles.suggestions}>
          {suggestions.map((suggestion, index) =>
            <li
              key={suggestion.name}
              className={selected === index ? styles.selected : null}
              onMouseDown={(e) => { 
                /* We use onMouseDown because it triggers before blur
                 * we prevent default to avoid loosing textarea focus
                 */
                e.preventDefault()
                e.stopPropagation()
                selectSuggestion(index)
              }}
            >{suggestion.name}</li>
          )}
        </ul>
      )}

      </div>
      {keywords.length > 0 &&
        <div className={styles.keywordsList}>
          {keywords.map(keyword => 
            <div
              key={keyword.id}
              className={styles.keywordItem}
            >
              {keyword.name}
              <button
                className={styles.removeButton}
                title="Remove this keyword"
                onClick={() => handleRemoveKeyword(keyword.id)}
              >×</button>
            </div>
          )}
        </div>
      }
    </div>
  )
}

PhotoKeywords.propTypes = {
  photoID: PropTypes.string.isRequired,
  keywords: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
}

export default PhotoKeywords