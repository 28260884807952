
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import styles from './formAutoSaveTextArea.module.css'


function FormAutoSaveTextArea({ submit, name, label, initialValue, formClassName, wrapperClassName, textareaClassName, placeholder }) {
  
  const [value, setValue] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    setValue(initialValue || '')
  }, [initialValue])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (value === initialValue) return
    submit(value)
    inputRef.current.blur()
  }

  const handleReset = () => {
    setValue(initialValue || '')
    inputRef.current.blur()
  }

  const handleKeyDown = (e) => {
    e.stopPropagation()
    if (e.key === "Escape") {
      handleReset()
    }
  }

  return (
    <form
      className={formClassName}
      onSubmit={handleSubmit}
    >
      <label htmlFor={`id-${name}`}>{label}</label>
      <div className={`${styles.wrapper} ${wrapperClassName}`}>
        <div className={`${styles.spring} ${textareaClassName}`} aria-hidden>
          {value}
          <br />{/* chrome is adding this br to textarea end, so we need it else one line is missing. */}
        </div>
        <textarea
          id={`id-${name}`}
          className={textareaClassName}
          name={name}
          value={value}
          placeholder={placeholder || ''}
          onBlur={handleSubmit}
          onChange={e => setValue(e.target.value)}
          onKeyDown={e => handleKeyDown(e)}
          ref={inputRef}
        />
      </div>
    </form>
  )
}

FormAutoSaveTextArea.propTypes = {
  submit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  formClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  textareaClassName: PropTypes.string,
  placeholder: PropTypes.string,
}


export default FormAutoSaveTextArea

