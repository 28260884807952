import PropTypes from 'prop-types'

import { gql, useMutation } from '@apollo/client'

import withModal from '../../hoc/withModal/withModal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'
import FormRequiredField from '../formRequiredField/FormRequiredField'
import FormRequiredFields from '../formRequiredFields/FormRequiredFields'
import FieldWrapper from '../fieldWrapper/FieldWrapper'
import FormErrors from '../formErrors/FormErrors'

const RENAME_KEYWORD_FORM = 'RENAME_KEYWORD_FORM'

const RENAME_KEYWORD_MUTATION = gql`
  mutation RenameKeyword($id: ID!, $name: String!) {
    renameKeyword(id: $id, name: $name) {
      id
      name
      count
    }
  }
`

const ME_QUERY = gql`
  query MeQuery {
    me {
      id
    }
  }
`

function RenameKeyword(props) {
  const originalId = props.modalOptions?.keyword.id
  const originalName = props.modalOptions?.keyword.name
  
  const [renameKeywordMutation, { loading, error }] = useMutation(RENAME_KEYWORD_MUTATION, {
    onCompleted: () => {
      props.closeModal()
    },
    update: (cache, { data: { renameKeyword }}) => {
      if (renameKeyword.id === originalId) {
        // keyword was renamed, cache updated alone
        return
      }

      function removeKeywordFromCacheList(existingKeywordRefs, { readField }) {
        return existingKeywordRefs.filter(keywordRef => originalId !== readField('id', keywordRef))
      }

      const cachedId = cache.identify(props.modalOptions.keyword)

      const { me } = cache.readQuery({ query: ME_QUERY })
      // Remove keyword from list
      cache.modify({
        id: cache.identify(me),
        fields: {
          keywords: removeKeywordFromCacheList,
        }
      })
      // Remove unused keyword
      cache.evict(cachedId)
    },
    onError: (e) => console.error(e),
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const newName = formData.get("name")
    if (newName && newName !== originalName) {
      renameKeywordMutation({ variables: {
        id: originalId,
        name: formData.get('name'),
      }})
    }
  }

  //console.log('RenameKeyword', props)
  return (
    <ModalSection>
      <ModalHeader
        title={`Rename keyword "${originalName}"`}
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <form
          id={RENAME_KEYWORD_FORM}
          onSubmit={handleSubmit}
        >
          <FormRequiredFields />
          <FieldWrapper>
            <label htmlFor="id-name">Name <FormRequiredField /></label>
            <input
              id="id-name"
              name="name"
              type="text"
              defaultValue={originalName}
              required
              autoFocus
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormErrors
              error={error}
            />
          </FieldWrapper>
        </form>

      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Renaming keyword..." />}
        <Submit
          primary={true}
          form={RENAME_KEYWORD_FORM}
          value={"Rename keyword"}
        />
      </ModalFooter>
    </ModalSection>
  )
}

RenameKeyword.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    keyword: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  }).isRequired,
}


export default withModal(RenameKeyword)



