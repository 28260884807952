import React from 'react'
import Header from '../header/Header'
import { gql } from '@apollo/client'
import { 
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'

import styles from './app.module.css'

import AuthenticationRequired from '../authenticationRequired/AuthenticationRequired'
import Login from '../login/Login'
import Signup from '../signup/Signup'
import UserProfile from '../userProfile/UserProfile'
import UserKeywords from '../userKeywords/UserKeywords'
import StartPasswordReset from '../startPasswordReset/StartPasswordReset'
import PasswordReset from '../passwordReset/PasswordReset'
import VerifieEmail from '../verifieEmail/VerifieEmail'
import Modal from '../modal/Modal'
import Library from '../library/Library'
import AllPhotos from '../allPhotos/AllPhotos'
import AlbumOrFolderDetail from '../albumOrFolderDetail/AlbumOrFolderDetail'
import PhotoDetail from '../photoDetail/PhotoDetail'
import Lightbox from '../lightbox/Lightbox'

export const AUTHENTICATED_QUERY = gql`
  query AuthenticatedQuery {
    me {
      id
      username
    }
  }
`

function App(props) {
  //console.log('App', props)
  
  return (
    <section
      role="main"
      className={styles.main}
    >
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/keywords" element={<UserKeywords />} />
        <Route path="/start_password_reset" element={<StartPasswordReset />} />
        <Route path="/password_reset" element={<PasswordReset />} />
        <Route element={<AuthenticationRequired />}>
          <Route path="/" element={<Library />} />
          <Route path="/verifie_email" element={<VerifieEmail />} />
          <Route path="/all" element={<AllPhotos />} />
          <Route path="/all/photo/:photoID" element={<PhotoDetail />} />
          <Route path="/all/photo/:photoID/lightbox/" element={<Lightbox />} />
          <Route path="/albums/:albumID" element={<AlbumOrFolderDetail />} />
          <Route path="/albums/:albumID/photo/:photoID" element={<PhotoDetail />} />
          <Route path="/albums/:albumID/photo/:photoID/lightbox/" element={<Lightbox />} />
        </Route>
      </Routes>
      <Modal />
    </section>
  )
}


function AppRoot(props) {
  return (
    <Router>
      <App />
    </Router>
  )
}

export default AppRoot
