import { makeVar } from '@apollo/client'

export const modalStatusVar = makeVar(null)
export const modalOptionsVar = makeVar(null)

export const modalTypes = {
  LOGOUT: 'LOGOUT',
  UPLOAD_AVATAR: 'UPLOAD_AVATAR',
  UPDATE_EMAIL: 'UPDATE_EMAIL',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',

  CREATE_PHOTOS: 'UPLOAD_PHOTOS',
  UPDATE_PHOTO: 'UPDATE_PHOTO',
  DELETE_PHOTOS: 'DELETE_PHOTOS',

  CREATE_FOLDER: 'CREATE_FOLDER',
  RENAME_FOLDER: 'RENAME_FOLDER',
  MOVE_FOLDER: 'MOVE_FOLDER',
  DELETE_FOLDER: 'DELETE_FOLDER',

  CREATE_ALBUM: 'CREATE_ALBUM',
  RENAME_ALBUM: 'RENAME_ALBUM',
  MOVE_ALBUM: 'MOVE_ALBUM',
  DELETE_ALBUM: 'DELETE_ALBUM',

  ADD_PHOTOS_TO_ALBUM: 'ADD_PHOTOS_TO_ALBUM',
  REMOVE_PHOTOS_FROM_ALBUM: 'REMOVE_PHOTOS_FROM_ALBUM',

  RENAME_KEYWORD: 'RENAME_KEYWORD',
  DELETE_KEYWORD: 'DELETE_KEYWORD',
}
